<template>
  <div>
    <div class="add_box form1 inde_btn">
      <div class="tabchild_pane">
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="Information Editing">
            <!-- contantNo -->
            <div v-if="message == 2 || message == 4">
              <h2 class="left_text margin_top"><span />Contract No: <i class="postFrom-contractNo">{{ form.keyword }}</i></h2>
              <el-form ref="postForm" :rules="rules" :model="form">
                <el-form-item label="" prop="keyword">
                  <template>
                    <!-- <el-input
                      v-model.trim="form.keyword"
                      placeholder="Input limit: Yes, add% to the default suffix"
                      size=""
                      style="width:320px"
                    /> -->
                    <el-select
                      v-model="form.keyword"
                      popper-class="enterpriseNameClass"
                      filterable
                      placeholder="Please enter company name"
                      style="width:420px"
                    >
                      <el-option
                        v-for="item in EnterpriseList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-form-item>
                <!-- <el-button
                  type="primary"
                  style="margin-left:20px"
                  @click="sunmtBtn"
                >
                  Check
                </el-button> -->
              </el-form>
            </div>
            <!-- 上传 -->
            <div>
              <h2 class="left_text"><span />Upload settings</h2>
              <el-upload
                class="upload-demo"
                drag
                :action="thisApi + '/system/ware/file/uploadRateFile'"
                multiple
                :show-file-list="false"
                :on-success="handsuccess"
              >
                <i class="el-icon-upload" />
                <div class="title_one">
                  Please put the Execl document here
                </div>
                <div class="title_two">
                  * Support JPG, XLSX, EML,MSG,TXT…, file size
                </div>
                <div class="title_three">
                  Upload File
                </div>
              </el-upload>

              <el-table
                v-loading="loading"
                :data="tableList"
                border
                fit
                :header-cell-style="{ background: '#e8f1fe' }"
                style="width: 100%"
              >
                <!-- 修改无数据时 提示文字 -->
                <template slot="empty" class="logo_no">
                  <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
                  <p style="color: #969799;">No more data</p>
                </template>
                <el-table-column
                  label="Uploader Name"
                  width="300"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.positionIndex || '--' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="File Name"
                  min-width="180"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.keyword || '--' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Operate" width="180" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="handleUpdate(scope.row)"
                    >
                      Download
                    </el-button>
                    <el-button
                      type="text"
                      size="small"
                      @click="handleDel(scope.$index)"
                    >
                      Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 三联 -->
            <div v-if="message == 1">
              <h2 class="left_text"><span />Choose Category</h2>
              <span class="center_sapn">Select the category or SKU you want to set</span>
              <Category
                ref="childenContor"
                :selectnodedata="datas"
                @change="getAllSelectLists"
              />
            </div>
            <div v-if="message == 2">
              <h2 class="left_text"><span />Choose Category</h2>
              <span class="center_sapn">Select the category or SKU you want to set</span>
              <Category
                ref="childenContor"
                :selectnodedata="datas"
                @change="getAllSelectLists"
              />
            </div>
            <!-- set -->
            <div v-if="message == 1 || message == 2">
              <h2 class="left_text margin_top">
                <span />Set the Discount Rate
              </h2>
              <el-form :inline="true" class="user-search fixed_form">
                <el-form-item label="" size="">
                  <template>
                    <el-input
                      v-model.trim.number="form.suffix"
                      class="input-view"
                      placeholder=""
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      maxlength="2"
                    />%
                  </template>
                </el-form-item>
                <!-- <el-button type="" style="margin-left:20px" @click="searchBtn">
                  change
                </el-button>
                <el-button type="primary" @click="addBtn">
                  Submit
                </el-button> -->
              </el-form>
            </div>
            <!-- add sku -->
            <div v-if="message == 3 || message == 4">
              <h2 class="left_text margin_top"><span />Add SKU ID</h2>
              <Addsku :icont="icont" :data-list-table="dataListTable" @resourceFlag="resourceFlagFn" @childFn="parentFn" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="Check the discount rate">
            <List :tab-type="message" :listid="objects" />
          </el-tab-pane>
          <el-tab-pane label="Approval Record">
            <Listapp :listid="objects" />
          </el-tab-pane>
        </el-tabs>
      </div>
      <div style="height:80px" />
      <div
        v-if="ifdit == '1' && tabIndex == 0"
        class="botton_btn"
        lang="center"
      >
        <el-button v-if="ifup == 1" type="primary" class="btns" @click="submit">
          Submit
        </el-button>
        <el-button v-else-if="ifup == 2 && objects.isApprovedState != 2" type="primary" class="btns" @click="upmist">
          Submit
        </el-button>
      </div>
      <div v-if="ifdit == '2' && tabIndex == 0">
        <h2 class="left_text margin_top"><span />Approval Information</h2>
        <el-form ref="formTi" :model="formTi" :rules="rulesFrom" class="">
          <el-form-item label="" size="" prop="auditStatus">
            <el-radio-group v-model.trim="formTi.auditStatus">
              <el-radio :label="1">Pass</el-radio>
              <el-radio :label="2">Reject</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" size="">
            <template>
              <el-input
                v-model.trim="formTi.auditContent"
                type="textarea"
                :rows="2"
                placeholder="please fill in the approval advice,ebter up to 20 words,be sure to concise"
              />
            </template>
          </el-form-item>
          <el-form-item>
            <el-button
              type=""
              size="small"
              @click="eidstate('formTi')"
            >
              Submit
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Category from '../../components/categoryCascader/categoryCascader/CategorySelect.vue';
// import Categoryt from '../../components/categoryCascader/categoryCascader/CategorySelect1.vue';
import List from '../../components/general/list.vue';
import Listapp from '../../components/general/approvalList.vue';
import Addsku from '../../components/general/addskulist.vue';
import {
  ifcontor,
  getpush,
  addRecord,
  approvalEdit,
  upRecord,
  addByCategory,
  editByCategory,
  downLoadText
} from '@/api/user/list';
import { getEnterpriseName } from '@/api/enterprise/enterprise';

const defaultForm = {
  remark: '',
  poolName: '',
  contractNo: '',
  skuId: ''
};

export default {
  name: 'Index',
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Category,
    List,
    Listapp,
    Addsku,
    // Categoryt
  },
  props: {
    message: {
      type: String
    },
    ifup: { //1 新增  2 编辑
      type: String
    },
    objects: {
      type: Object
    },
    ifdit: {
      type: String
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      ifcontor(value).then(res => {
        this.nameOK = res.data == true ? true : false;
      });
      if (this.nameOK != false) {
        callback();
      } else {
        callback(new Error('Description Failed to verify the contract no'));
      }
    };
    return {
      emptyImgSrc: '',
      tableList: [],
      loading: false,
      form: {
        keyword: '',
        suffix: ''
      },
      childContor: [],
      threId: [],
      listid: {
        id: '',
        contractNo: ''
      },
      icont: '1',
      listTree: [],
      datas: [],
      recardId: '',
      childjdPrice: '',
      formTi: {
        auditStatus: 0,
        auditContent: ''
      },
      rules: {
        keyword: [
          {
            required: true,
            message: 'Enter the Contract No',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: validatePass
          }
        ]
      },
      rulesFrom: {
        auditStatus: [
          { required: true, message: 'Please select a review status', trigger: 'change' }
        ]
      },
      nameOK: true,
      thisApi: process.env.VUE_APP_BASE_API,
      tabIndex: 0,
      EnterpriseList: [],
      resourceFlagParent: 1,
      // 存放接口数据
      dataListTable: []
    };
  },
  watch: {
    message: function() {
      this.tableList = [];
      this.loading = false;
      this.form.keyword = '';
      this.form.suffix = '';
      this.childContor = [];
      // this.threId = [];
    }
  },
  created() {
    this.emptyImgSrc = '';
    if (this.ifup === '2') {
      var obj = {
        id: this.objects.id,
        contractNo: this.objects.contractNo,
      };
      this.getpush(obj);
    }
    if (this.message === '2' || this.message === '4') {
      this.getE();
    }
  },
  mounted() {
  },
  methods: {
    resourceFlagFn(val) {
      this.resourceFlagParent = val;
    },
    // 根据企业名称查询 合同号
    getE() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
          
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab.index);
      this.tabIndex = tab.index;
    },
    proving() {
      console.log(this.form.suffix.replace(/[^\.\d]/g, ''));
      this.form.suffix = this.form.suffix.replace(/[^\.\d]/g, '');
      this.form.suffix = this.form.suffix.replace('.', '');
    },

    contfNull() {
      if (this.ifup == 2) {
        // this.$refs.childenContorOne.requestLists2(this.listTree);
      }
    },
    eidstate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var obj = {
            auditBody: 1,
            auditType: 1,
            auditKey: this.objects.id,
            auditStatus: this.formTi.auditStatus,
            auditContent: this.formTi.auditContent
          };
          approvalEdit(this.objects.contractNo, {
            auditLogDTO: obj,
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'Review the success',
                type: 'success'
              });
              this.listid.id = this.objects.id;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
            console.log(res);
          });
        } else {
          return false;
        }
      });
      
    },
    getpush(res) {
      getpush(res).then(res => {
        if (res.code == 200) {
          this.dataListTable = res.data.discountRates;
          this.datas = res.data.listTree;
          if(res.data.discountRates[0]){
            this.form.suffix = res.data.discountRates[0].discountRate;
          }
          if (res.data.discountRecord) {
            this.form.keyword = res.data.discountRecord.contractNo;
            this.recardId = res.data.discountRecord.id;
            let nameState = 0;
            if (res.data.discountRecord.approvedState == 2) {  // 回显通过
              nameState = 1;
            } else if (res.data.discountRecord.approvedState == 3) {  // 回显拒绝
              nameState = 2;
            } else { // 待审核
              nameState = 0;
            }
            this.formTi.auditStatus = nameState;
            this.formTi.auditContent = res.data.discountRecord.auditContent || '';
          }
          this.childContor = res.data.discountRates;
          this.childjdPrice = res.data.mallPrice;
        
          var list = [];
          for (let i = 0; i < res.data.approveFiles.length; i++) {
            const element = res.data.approveFiles[i];
            element.positionIndex = element.fileName;
            element.keyword = element.fileUrl;
            list.push(element);
          }
          this.tableList = list;
        } else {
          // 暂无数据
          this.datas = [];
          this.tableList = [];
        }
        
      });
    },
    getAllSelectLists(data) {
      // for (let i = 0; i < data[2].length; i++) {
      //   let element = data[2][i];
      //   element.contractNo =
      //     this.form.keyword == '' ? 'default' : this.form.keyword;
      //   element.skuId = element.id;
      //   element.discountRate = this.form.suffix;
      //   // element.discountAmount = this.form.suffix;
      //   element.discountRate = this.form.suffix;
      //   element.thrCateId = element.id;
      //   element.approvedState = 0;
      //   element.state = 0;
      //   // element.dataVersion = 1;
      //   element.discountType = this.message;
      //   element.skuName = element.name;
      //   element.modifier = '';
      //   element.modified = '';
      //   delete element.approvedState;
      //   delete element.checked;
      //   delete element.childList;
      //   delete element.disabled;
      //   delete element.level;
      //   delete element.pid;
      //   delete element.pValue;
      //   delete element.status;
      //   delete element.value;
      // }
      // this.childContor = data[2];
      let arr = [];
      for (let i = 0; i < data[2].length; i++) {
        let element = data[2][i];
        arr.push(element.id)
      }
      this.childContor = arr;
    },
    descartes(array) {
      if (array.length < 2) return array[0] || [];
      return [].reduce.call(array, function(col, set) {
        var res = [];
        col.forEach(function(c) {
          set.forEach(function(s) {
            var t = [].concat(Array.isArray(c) ? c : [c]);
            t.push(s);
            res.push(t);
          });
        });
        return res;
      });
    },
    parentFn(payload) {
      if (payload.list && payload.list.length > 0) {
        for (let i = 0; i < payload.list.length; i++) {
          let element = payload.list[i];
          element.contractNo =
            this.form.keyword == '' ? 'default' : this.form.keyword;
          // element.dataVersion = 1;
          element.discountType = +this.message;
          if (element.contractNo != 'default') {
            this.EnterpriseList.forEach((item,index) => {
              if (item.value === this.form.keyword) {
                element.enterpriseName = item.label
              }
            })
          }
          delete element.brandId;
          delete element.brandName;
          delete element.costPrice;
          delete element.count;
          delete element.created;
          delete element.creator;
          delete element.firCateId;
          delete element.firCateName;
          delete element.id;
          delete element.remark;
          delete element.saleState;
          delete element.secCateId;
          delete element.secCateName;
          delete element.skuPoolRelations;
          delete element.source;
          delete element.spuId;
          delete element.thrCateName;
          delete element.venderId;
          delete element.venderType;
          delete element.venderTypeName;
          delete element.checkOut;
          delete element.codeType;
          delete element.detailPictures;
          delete element.detailRule;
          delete element.mainPicture;
          delete element.originalPrice;
          delete element.page;
          delete element.saleAtts;
          delete element.shippingAddress;
          delete element.shippingAddressVOList;
          delete element.shoppingColor;
          delete element.shoppingModel;
          delete element.size;
          delete element.skuPicturePrefix;
          delete element.upcCode;
          delete element.wareId;
        }
        this.childContor = payload.list;
      } else {
        // 暂无数据
      }
      
    },
    searchBtn() {},
    addBtn() {
      this.$emit('childFn', 1);
    },
    sunmtBtn() {
      ifcontor(this.form.keyword).then(res => {
        if (!res.data) {
          this.$message({
            message: 'Description Failed to check the Contract No',
            type: 'error'
          });
        } else {
          this.$message({
            message: 'Contract inspection successful',
            type: 'success'
          });
        }
      });
    },
    handsuccess(file, fileList) {
      var obj = {
        positionIndex: file.data.name,
        keyword: file.data.path
      };
      this.tableList.push(obj);
    },
    handleUpdate(row) {
      // window.location.href =
      //   this.thisApi +
      //   '/system/ware/file/downloadTarget?fileName=' +
      //   row.keyword;
      downLoadText(row.keyword).then(res => {
        this.isExcel(row.positionIndex.split('.')[1], row.positionIndex.split('.')[0], res);
      });
    },
    isExcel(type, name, data) {
      const link = document.createElement('a');
      const blob = new Blob([data]);
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${name}.` + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleDel(index) {
      this.tableList.splice(index, 1);
    },
    // 新增
    submit() {
      var list = [];
      for (let i = 0; i < this.tableList.length; i++) {
        let element = this.tableList[i];
        // element.approveType = 1;
        element.fileName = element.positionIndex;
        element.fileUrl = element.keyword;
        // element.state = 0;
        // element.modifier = '';
        // element.modified = '';
        // element.dataVersion = 1;
        delete element.keyword;
        delete element.positionIndex;
        list.push(element);
      }
      if (this.message == 3 || this.message == 4) {
        for (let j = 0; j < this.childContor.length; j++) {
          let element = this.childContor[j];
          element.approvedState = 0;
          // element.discountRate =
          //   element.discountRate == 0 ? this.form.suffix : element.discountRate;
          delete element.name;
          delete element.sellingPrice;
          delete element.cartDate;
          // delete element.discountRate;
          if (!/^([0-9]{1,2}|100)$/.test(element.discountRate)) {
            this.$message({
              message: 'The discount rate should be between 0%-100%',
              type: 'error'
            });
            return
          }
          if (element.discountSale < 0) {
            this.$message({
              message: 'Sale Price cannot be less than 0',
              type: 'error'
            });
            return;
          }
        }
        let object = {
          discountRecord: {
            contractNo: this.form.keyword == '' ? 'default' : this.form.keyword,
            discountType: +this.message,
            discountName: '',
            approvedState: 0,
            state: 0,
          },
          discountRates: this.childContor,
          approveFiles: list
        };
        if (object.discountRates.length == 0) {
          this.$message({
            message: 'Please enter complete information',
            type: 'error'
          });
          return;
        }
        if (this.message == 4) {
          this.$refs['postForm'].validate(valid => {
            if (valid) {
              this.getAddRecordFn(object);
            } else {
              return false;
            }
          });
        } else {
          this.getAddRecordFn(object);
        }
      } else {
        let object12 = {
          discountRecord: {
            contractNo: this.form.keyword == '' ? 'default' : this.form.keyword,
            discountType: +this.message,
            discountName: '',
            approvedState: 0,
            state: 0,
          },
          approveFiles: list,
          categorys: this.childContor ? this.childContor.toString() : '',
          rate: this.form.suffix,
          contractNo: this.form.keyword == '' ? 'default' : this.form.keyword
        };
        if (object12.rate == '' || object12.categorys == '') {
          this.$message({
            message: 'Please enter complete information',
            type: 'error'
          });
          return;
        } else {
          if (this.message == 1) {
            this.getAddByCategoryFn(object12);
          } else {
            this.$refs['postForm'].validate(valid => {
              if (valid) {
                this.getAddByCategoryFn(object12)
              } else {
                return false;
              }
            });
          }
        }
      }
    },
    // 折扣在 1 和 2 的时候 创建
    getAddByCategoryFn(object) {
      addByCategory(object).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'New discount rate succeeded',
            type: 'success'
          });
          this.listid.id = res.data;
          this.listid.contractNo = this.form.keyword;
          this.$router.push({path:'/discountManage/discountTable',query:{t:Date.now()}})
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 折扣在 1 和 2 的时候 编辑
    getEditByCategoryFn(object) {
      editByCategory(object).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'New discount rate succeeded',
            type: 'success'
          });
          this.listid.id = res.data;
          this.listid.contractNo = this.form.keyword;
          this.$router.push({path:'/discountManage/discountTable',query:{t:Date.now()}})
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 3  和 4 创建
    getAddRecordFn(object) {
      addRecord(object).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'New discount rate succeeded',
            type: 'success'
          });
          this.listid.id = res.data;
          this.listid.contractNo = this.form.keyword;
          this.$router.push({path:'/discountManage/discountTable',query:{t:Date.now()}})
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 3  和 4 编辑
    getUpRecordFn(object) {
      upRecord(object).then(res => {
        if (res.code == 200) {
          this.$message({
            message: 'New discount rate succeeded',
            type: 'success'
          });
          this.listid.id = res.data;
          this.listid.contractNo = this.form.keyword;
          this.$router.push({path:'/discountManage/discountTable',query:{t:Date.now()}})
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 编辑
    upmist() {
      var list = [];
      for (let i = 0; i < this.tableList.length; i++) {
        let element = this.tableList[i];
        // element.approveType = 1;
        element.fileName = element.keyword;
        element.fileUrl = element.positionIndex;
        // element.state = 0;
        // element.modifier = '';
        // element.modified = '';
        // element.dataVersion = 1;
        delete element.keyword;
        delete element.positionIndex;
        list.push(element);
      }
      if (this.message == 3 || this.message == 4) {
        for (let j = 0; j < this.childContor.length; j++) {
          let element = this.childContor[j];
          // element.discountRate = element.discountRate = this.form.suffix;
          element.recordId = this.objects.id;
          element.approvedState = 1;
          element.thrCateName = '';
          delete element.cartDate;
          delete element.name;
          delete element.approvedState;
          delete element.fileList;
          delete element.filePath;
          delete element.page;
          delete element.size;
          delete element.size;
          delete element.tableName;
          delete element.venderTypeName;
          delete element.sellingPrice;
          // delete element.discountRate;
          if (!/^([0-9]{1,2}|100)$/.test(element.discountRate)) {
            this.$message({
              message: 'The discount rate should be between 0%-100%',
              type: 'error'
            });
            return
          }
          if (element.discountSale < 0) {
            this.$message({
              message: 'Sale Price cannot be less than 0',
              type: 'error'
            });
            return;
          }
        }
        let object = {
          discountRates: this.childContor,
          approveFiles: list,
          recordId: this.objects.id,
          contractNo: this.form.keyword == '' ? 'default' : this.form.keyword
        };
        if (object.discountRates.length == 0) {
          this.$message({
            message: 'Please enter complete information',
            type: 'error'
          });
          return;
        } else {
          this.getUpRecordFn(object);
        }
      } else {
        let object12 = {
          approveFiles: list,
          categorys: this.childContor ? this.childContor.toString() : '',
          rate: this.form.suffix,
          contractNo: this.form.keyword == '' ? 'default' : this.form.keyword,
          recordId: this.objects.id
        };
        if (object12.rate == '' || object12.categorys == '') {
          this.$message({
            message: 'Please enter complete information',
            type: 'error'
          });
          return;
        } else {
          this.getEditByCategoryFn(object12);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 3vh;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.btn_box {
  display: flex;
  margin-left: 36px;
}
.upload-demo > div {
  width: 203px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.fill_btn {
  width: 132px;
  height: 32px;
  background: rgba(34, 98, 255, 1);
  border-radius: 4px;
  padding: 0;
  margin-left: 16px;
}
/deep/.el-form-item__content {
  display: flex;
}
/deep/.el-button {
  // padding: 0 12px !important;
}
.left_text {
  font-size: 16px;
  line-height: 20px;
  margin-top: 14px;
  display: block;
  span {
    border-left: 4px solid #2262ff;
    margin-right: 20px;
  }
  .postFrom-contractNo{
    font-style: normal;
    margin-left: 10px;
    font-weight: normal;
  }
  box-sizing: border-box;
}
/deep/.el-upload-dragger {
  width: 100%;
  height: auto;
}
/deep/.el-upload {
  width: 100%;
  height: auto;
}
.tabchild_pane {
  padding: 0;
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #2262ff !important;
    background-color: #fff;
    background: rgba(255, 255, 255, 1) !important;
    border: 1px solid rgba(224, 226, 234, 1) !important;
    height: auto !important;
    text-align: center;
    line-height: 48px;
    margin: 1px;
    box-sizing: border-box;
    width: auto !important;
  }
  /deep/.el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }
  /deep/.el-tabs__nav {
    width: auto !important;
    display: flex;
    justify-content: space-around;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    height: 40px !important;
    width: auto !important;
  }
  /deep/.el-tabs__item {
    padding: 0 20px !important;
    height: 40px !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    line-height: 40px !important;
    display: inline-block !important;
    list-style: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #303133 !important;
    position: relative !important;
  }
}
.title_one {
  font-size: 14px;
  font-family: ArialMT;
  color: #21233c;
}
.title_two {
  padding: 16px;
  font-size: 14px;
  font-family: ArialMT;
  color: #242526;
}
.title_three {
  width: 240px;
  height: 36px;
  background: rgba(231, 238, 255, 1);
  border: 1px solid rgba(34, 98, 255, 1);
  border-radius: 4px;
  line-height: 36px;
  margin: 0 auto 16px auto;
  color: #2262ff;
}
.center_sapn {
  font-size: 14px;
  line-height: 30px;
  color: #242526;
  padding-left: 24px;
  margin-bottom: 10px;
  display: block;
}
.margin_top {
  margin-top: 34px;
}
.fixed_form {
  width: 100%;
  display: flex;
  align-items: center;
  div {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.botton_btn {
  position: absolute;
  width: 100%;
  z-index: 9999;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
  .btns {
    width: 240px;
    height: 40px;
    background: rgba(34, 98, 255, 1);
    border-radius: 4px;
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}
</style>
