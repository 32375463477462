<!--Check the discount rate-->
<template>
  <div class="panel CheckLst">
    <div class="panel-body">
      <el-table
        :data="tableList"
        border
        fit
        style="width: 100%"
        size="mini"
        :header-cell-style="{ background: '#e8f1fe' }"
        @row-click="listClick"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799;">No more data</p>
        </template>
        <el-table-column
          label="Approval task number"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-button type="text">
              {{ scope.row.id || '--' }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="Submitter ERP"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.subCreater || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Auditor ERP"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="socpe">
            <span>{{ socpe.row.creator || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Auditor‘s Opinion"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.auditContent || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Audition Result"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-for="(items, index) in UserFlagList" :key="index">
              <span>{{ scope.row.auditStatus == 1 ? 'pass' : 'reject' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Audition Status"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-for="(item, index) in approvalStatusList"
              v-show="item.value == scope.row.approvedState"
              :key="index"
            >
              {{ item.label || '--' }}
            </div>
            <!-- <span>{{ scope.row.approvedState == 1 ? 'pass' : 'reject' }}</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="Submission Time"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.created || '--' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// 引入API接口
import { getDifcode, userUnBind } from '@/api/user/list';
export default {
  // 引入局部刷新
  inject: ['reload'],
  props: ['listid'],
  data() {
    return {
      emptyImgSrc: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      // 搜索条件
      form: {
        pin: '',
        contractNo: '',
        userType: '',
        state: '',
        salesERP: ''
      },
      // 字典表
      UserFlagList: [{ value: '1', label: 'VSP' }],
      ValidityList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'Invalid' }
      ],
      // 后端获取表格信息
      tableList: [],
      // 选中列表信息项
      multipleSelection: '',
      // 定义tabel切换初始位置
      outeObject: this.$route.query,
      unbind: false,
      approvalStatusList: [
        { value: 1, label: 'Waiting for approval' },
        { value: 2, label: 'Approved' },
        { value: 3, label: 'Rejected' }
      ]
    };
  },
  watch: {
    outeObject: function(val) {
      //console.log(val);
    },
    'listid.id': function(val) {
      this.getFirstData();
    }
  },
  created() {
    this.emptyImgSrc = '';
    // 初始化表数据
    if (!this.outeObject.id) {
      this.unbind = false;
    } else {
      this.form.contractNo = this.outeObject.contractNo;
      this.unbind = true;
      this.getFirstData();
    }
  },
  methods: {
    // 获取页面数据
    getFirstData() {
      getDifcode({
        auditLogDTO: {
          auditKey: this.listid.id ? this.listid.id : this.outeObject.id
        },
        pageNo: this.listQuery.pageCode,
        pageSize: this.listQuery.pageSize,
      }).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total  = res.data.totalCount;
        } else {
          this.tableList = [];
          this.listQuery.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.$router.push({
        name: 'User Management New/edit',
        path: '/user/add'
      });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
    },
    // 点击行时触发
    listClick(row) {
      this.multipleSelection = row;
    },
    // 修改按钮
    editBtn() {
      if (this.multipleSelection == '') {
        this.$confirm('Please Choose what you want to modify!', 'warning', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then(() => {})
          .catch(function() {});
      } else {
        this.$router.push({
          name: 'change password',
          path: '/user/changePassword',
          query: { cont: this.multipleSelection }
        });
      }
    },
    //解绑按钮
    unbindBnt(row) {
      this.$confirm('Whether to unbind the user', 'warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          var obj = {
            pin: row.pin,
            contractNo: row.contractNo
          };
          userUnBind(obj).then(res => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                message: 'Unbundling success',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.push({
                  name: 'contract list',
                  path: '/contractManage/contractList'
                });
              }, 1000);
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        })
        .catch(function() {});
    }
  }
};
</script>
<style lang="less" scoped>
.CheckLst {
  margin-bottom: 40px;
  margin-top: 10px;
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  ::v-deep .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.serch_box {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 16px solid #eff0f4;
}
.fixed_form {
  display: flex;
  flex-wrap: wrap;
  div {
    display: flex !important;
    flex-direction: column !important;
    /deep/.el-form-item__label {
      text-align: left !important;
    }
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
}
</style>
