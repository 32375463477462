<!--New discount rate-->
<template>
  <div>
    <div class="add_box form1">
      <h2 class="titles_htwo">Discount category selection</h2>
      <div class="tab_pane">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane v-for="(tab,index) in tabsPanle" :disabled="disabledFn(tab)" :key="index" :name="tab.name" :label="tab.label">
            <div v-if="activeName===tab.name">
              <Tabel
                :message="tab.name"
                :cont="conter"
                :ifup="ifup"
                :ifdit="ifdit"
                :objects="objects"
                @childFn="parentFn"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
        <!-- <el-tabs v-model="activeName" type="border-card" @tab-click="clack">
          <el-tab-pane
            label="Category-General Discount Rate"
            name="1"
            :disabled="activeName != 1 && iftab"
          >
            <Tabel
              :message="msgOne"
              :cont="conter"
              :ifup="ifup"
              :ifdit="ifdit"
              :objects="objects"
              @childFn="parentFn"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Category-Exclusive Discount Rate"
            name="2"
            :disabled="!activeName == 2 && iftab"
          >
            <Tabel
              :message="msgTwo"
              :cont="conter"
              :ifup="ifup"
              :ifdit="ifdit"
              :objects="objects"
              @childFn="parentFn"
            />
          </el-tab-pane>
          <el-tab-pane
            label="SKU-General Discount Rate"
            name="3"
            :disabled="activeName !== 3 && iftab"
          >
            <Tabel
              :message="msgThree"
              :cont="conter"
              :ifup="ifup"
              :ifdit="ifdit"
              :objects="objects"
              @childFn="parentFn"
            />
          </el-tab-pane>
          <el-tab-pane
            label="SKU-Exclusive Discount Rate"
            name="4"
            :disabled="activeName !== 4 && iftab"
          >
            <Tabel
              :message="msgFour"
              :cont="conter"
              :ifup="ifup"
              :ifdit="ifdit"
              :objects="objects"
              @childFn="parentFn"
            />
          </el-tab-pane>
          <Tabel
            :message="msgFour"
            :cont="conter"
            :ifup="ifup"
            :ifdit="ifdit"
            :objects="objects"
            @childFn="parentFn"
          />
        </el-tabs> -->
      </div>
    </div>
  </div>
</template>

<script>
import Tabel from '../../components/general/index.vue';
import { addSKU, addxinhui } from '@/api/user/list';
const defaultForm = {
  remark: '',
  poolName: '',
  contractNo: '',
  skuId: ''
};

export default {
  name: 'Addcommodity',
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Tabel
  },
  data() {
    return {
      // msgOne: '1',
      // msgTwo: '2',
      // msgThree: '3',
      // msgFour: '4',
      conter: '',
      outeObject: this.$route.query,
      ifup: '1',//1 新增  2 编辑
      objects: {},
      activeName: '1',
      tabsPanle: [
        { name: '1', label: 'Category-General Discount Rate' },
        { name: '2', label: 'Category-Exclusive Discount Rate' },
        { name: '3', label: 'SKU-General Discount Rate' },
        { name: '4', label: 'SKU-Exclusive Discount Rate' }
      ],
      iftab: false,
      ifdit: '1',
    };
  },
  created() {
    if (!this.outeObject.id) {
      this.ifup = '1';
    } else {
      this.ifup = '2';
      this.objects = {...this.outeObject};
      this.activeName = this.outeObject.discountType + '';
      this.iftab = true;
      this.ifdit = this.outeObject.flang;
    }
  },
  methods: {
    parentFn(payload) {
      //console.log(payload);
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.activeName = tab.name;
    },
    disabledFn(tab) {
      if (this.activeName !== tab.name && this.iftab) {
        return true;
      } 
      if (!this.activeName == tab.name && this.iftab) {
        return true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.btn_box {
  display: flex;
  margin-left: 36px;
}
.upload-demo > div {
  width: 203px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.fill_btn {
  width: 132px;
  height: 32px;
  background: rgba(34, 98, 255, 1);
  border-radius: 4px;
  padding: 0;
  margin-left: 16px;
}
/deep/.el-form-item__content {
  display: flex;
}
/deep/.el-button {
  // padding: 0 12px !important;
}
.tab_pane {
  /deep/.el-tabs__nav {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  /deep/.el-tabs--border-card > .el-tabs__header {
    background-color: #fff;
    border-bottom: none;
  }
  /deep/.el-tabs--border-card {
    background: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #2262FF;
    background-color: #fff;
    border: 1px solid rgba(34, 98, 255, 1);
    background: rgba(239, 244, 255, 1);
    height: 48px;
    width: 270px;
    text-align: center;
    line-height: 48px;
    margin-top: 1px;
  }
  /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
    height: 48px;
    width: 270px;
    text-align: center;
    line-height: 48px;
    margin-top: 1px;
  }
  /deep/.el-tabs__nav .is-top {
    height: 55px;
  }
}
.titles_htwo {
  font-size: 16px;
  font-family: Arial;
  color: rgba(36, 37, 38, 1);
  line-height: 30px;
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
